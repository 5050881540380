import { Waypoint } from 'react-waypoint'

import LoadingSpinner from '../LoadingSpinner'
import EventCard from '../EventCard'

import * as styles from './EventFeed.styles'
import Button from '../Button'
import { EventDetailsFragment } from '../../graphql/fragments/eventDetails.fragment.gen'
import { Link } from '@remix-run/react'

interface EventFeedProps {
  events: EventDetailsFragment[] | undefined
  hasNextPage: boolean | undefined
  loading: boolean
  loadMoreEvents: () => void
  children?: React.ReactNode
  noPadding?: boolean
}

const EventFeed: React.FC<EventFeedProps> = ({
  events = [],
  loading,
  loadMoreEvents,
  hasNextPage,
  children,
  noPadding,
}) => {
  const footer = hasNextPage ? (
    <>
      <Waypoint onEnter={loadMoreEvents} />
      <Button hollow onClick={loadMoreEvents}>
        Load more
      </Button>
    </>
  ) : (
    <>
      <p css={styles.endOfListMessage}>
        These are all of the events in this list. <br />
        Discover more at <Link to="/">Home</Link>?
      </p>
      {/* <Button onClick={loadMoreEvents}>Check for more</Button> */}
    </>
  )

  return (
    <section css={[styles.eventFeedContainer, noPadding && styles.noPadding]}>
      {children}

      {events.map((event) => (
        <EventCard
          key={`eventcard-${event.id}`}
          event={event}
          displayFooter={true}
        />
      ))}

      {loading ? <LoadingSpinner /> : footer}
    </section>
  )
}

export default EventFeed
