import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'

let { breakpoint, size, pageWidth, fontColor } = mTheme

export let eventFeedContainer: Style = css({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${size.S}`,
  marginBottom: size.S,
  gap: size.S,
  [breakpoint.medium]: {
    minWidth: pageWidth.M,
  },
})

export let noPadding: Style = css({
  padding: 0,
})

export let endOfListMessage: Style = css({
  textAlign: 'center',
  fontStyle: 'italic',
  color: fontColor.plain.tertiary,
  marginBottom: size.M,
})

export let citySelectorContainer = css({
  display: 'inline-block',
})
